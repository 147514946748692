import React from "react"
import { Link } from "gatsby"

import Logo from "../components/logo"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => {
  return (
    <Layout className="legal">
      <SEO title="Terms and Conditions" />
      <div className="container">
        <Link to="/">
          <h1
            style={{
              width: "20rem",
              margin: "0 auto 2.4rem",
            }}
          >
            <Logo />
          </h1>
        </Link>
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          src="https://www.iubenda.com/terms-and-conditions/58712151"
        />
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
