import React from "react"

const Logo = () => (
  <svg
    className="logo"
    viewBox="0 0 223 107"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
  >
    <path
      d="M0 4.43213H30.9201V24.4511H20.2553L0 4.43213ZM86.7159 24.4424H55.6212L35.3572 4.43213H66.4519L86.7159 24.4424Z"
      fill="#F7941D"
      className="light-t"
    />
    <path
      d="M102.176 0L122.431 20.1931V106.991H118.125L97.9047 86.9723H102.167V0H102.176Z"
      fill="#F7941D"
      className="light-i"
    />
    <path
      d="M202.919 0L223 20.1931L189.241 106.991H185.328L165.073 86.7982H168.986L202.919 0Z"
      fill="#F7941D"
      className="light-v"
    />
    <path
      d="M55.6212 24.2944L35.6978 4.43213H35.3572V86.8068L55.6212 107V24.4511V24.2944Z"
      fill="#F15A29"
      className="dark-t"
    />
    <path
      d="M173.563 63.9492L167.344 79.893L149.22 33.3069L147.621 28.8747L136.433 0L156.504 20.1931L173.563 63.9492Z"
      fill="#F15A29"
      className="dark-v"
    />
  </svg>
)

export default Logo
